import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import NavButtons from 'components/Advanced_User/NavButtons';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "Control your Camera over MQTT with your Android Phone",
  "path": "/Advanced_User/Node-RED_and_MQTT/Smartphone_Integration/",
  "dateChanged": "2018-09-20",
  "author": "Mike Polinowski",
  "excerpt": "Node-RED is a programming tool for wiring together hardware devices like your INSTAR IP camera and APIs & online services. It provides a browser-based editor that makes it easy to wire together flows using the wide range of nodes providing inter-connectivity between your cameras REST API and IoT protocols like MQTT (Message Queuing Telemetry Transport). Compatible with the camera models IN-6001 HD, IN-6012 HD, IN-6014 HD, IN-5905 HD, IN-5907 HD, IN-7011 HD, IN-8003 Full HD, IN-8015 Full HD, IN-9008 Full HD, IN-9020 Full HD",
  "image": "./AU_SearchThumb_Node-RED.png",
  "social": "/images/Search/AU_SearchThumb_Node-RED.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_Advanced_NodeRED_white.webp",
  "chapter": "Advanced User",
  "category": "smarthome",
  "type": "Node-RED"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <SEOHelmet title='Control your Camera over MQTT with your Android Phone' dateChanged='2018-09-20' author='Mike Polinowski' tag='INSTAR IP Camera' description='Node-RED is a programming tool for wiring together hardware devices like your INSTAR IP camera and APIs & online services. It provides a browser-based editor that makes it easy to wire together flows using the wide range of nodes providing inter-connectivity between your cameras REST API and IoT protocols like MQTT (Message Queuing Telemetry Transport) Compatible with the camera models IN-6001 HD, IN-6012 HD, IN-6014 HD, IN-5905 HD, IN-5907 HD, IN-7011 HD, IN-8003 Full HD, IN-8015 Full HD, IN-9008 Full HD, IN-9020 Full HD.' image='/images/Search/AU_SearchThumb_Node-RED.png' twitter='/images/Search/AU_SearchThumb_Node-RED.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Erweitert/Node-RED_und_MQTT/Smartphone_Integration/' locationFR='/fr/Advanced_User/Node-RED_and_MQTT/Smartphone_Integration/' crumbLabel="Node-RED" mdxType="BreadCrumbs" />
 
    <NavButtons mdxType="NavButtons" />
    <h1 {...{
      "id": "control-your-camera-over-mqtt-with-your-android-phone",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#control-your-camera-over-mqtt-with-your-android-phone",
        "aria-label": "control your camera over mqtt with your android phone permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Control your Camera over MQTT with your Android Phone`}</h1>
    <p>{`The MQTT Protocol is supported by a wide range of third-party software. In the following we want to take a look at two Android apps that will allow us to use the MQTT Endpoints we added to our `}<a parentName="p" {...{
        "href": "/en/Advanced_User/Node-RED_and_MQTT/Projects/"
      }}>{`Node-RED`}</a>{` flows to control our camera from our smartphone.`}</p>
    {/* TOC */}
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#iot-mqtt-panel"
        }}>{`IoT MQTT Panel`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#mqtt-dashboard"
        }}>{`MQTT Dashboard`}</a></li>
    </ul>
    {/* /TOC */}
    <h2 {...{
      "id": "iot-mqtt-panel",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#iot-mqtt-panel",
        "aria-label": "iot mqtt panel permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`IoT MQTT Panel`}</h2>
    <p>{`First, go to the Google Play Store on your Android phone to download the App `}<a href="https://play.google.com/store/apps/details?id=snr.lab.iotmqttpanel.prod&hl=en" target="_blank" rel="noopener noreferrer">{`IoT MQTT Panel`}</a>{`.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "730px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/818a04cf88196685d0ca645deb0920da/1a144/IoT_MQTT_Panel_01.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "115.65217391304347%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAXABQDASIAAhEBAxEB/8QAGAABAAMBAAAAAAAAAAAAAAAAAAECBAX/xAAWAQEBAQAAAAAAAAAAAAAAAAABAgP/2gAMAwEAAhADEAAAAelbSTM0EmpF1F5//8QAHBAAAgEFAQAAAAAAAAAAAAAAAQMAAhESExQg/9oACAEBAAEFAqUKnOqaFeTYDIGf/8QAGBEAAwEBAAAAAAAAAAAAAAAAAAEREjH/2gAIAQMBAT8BiMoXSn//xAAYEQADAQEAAAAAAAAAAAAAAAAAARIQEf/aAAgBAgEBPwGmU84f/8QAGRAAAgMBAAAAAAAAAAAAAAAAADIBEBEg/9oACAEBAAY/AlEgTna//8QAGhABAAIDAQAAAAAAAAAAAAAAAQAxEBEhUf/aAAgBAQABPyFB04R8kKrDA1N0ypc7P//aAAwDAQACAAMAAAAQUM+//8QAGBEBAQADAAAAAAAAAAAAAAAAAQAhQWH/2gAIAQMBAT8QX1cLMEoW/8QAGBEBAAMBAAAAAAAAAAAAAAAAAAERYTH/2gAIAQIBAT8Q1aJ4o//EABwQAQADAAMBAQAAAAAAAAAAAAEAESExUXGBof/aAAgBAQABPxBGvTs4ghYkE5+sAoBXUEMAPsFu2QzQB5Gm001ywwA+hP/Z')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/818a04cf88196685d0ca645deb0920da/e4706/IoT_MQTT_Panel_01.avif 230w", "/en/static/818a04cf88196685d0ca645deb0920da/d1af7/IoT_MQTT_Panel_01.avif 460w", "/en/static/818a04cf88196685d0ca645deb0920da/b6582/IoT_MQTT_Panel_01.avif 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/818a04cf88196685d0ca645deb0920da/a0b58/IoT_MQTT_Panel_01.webp 230w", "/en/static/818a04cf88196685d0ca645deb0920da/bc10c/IoT_MQTT_Panel_01.webp 460w", "/en/static/818a04cf88196685d0ca645deb0920da/87ca7/IoT_MQTT_Panel_01.webp 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/818a04cf88196685d0ca645deb0920da/e83b4/IoT_MQTT_Panel_01.jpg 230w", "/en/static/818a04cf88196685d0ca645deb0920da/e41a8/IoT_MQTT_Panel_01.jpg 460w", "/en/static/818a04cf88196685d0ca645deb0920da/1a144/IoT_MQTT_Panel_01.jpg 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/jpeg"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/818a04cf88196685d0ca645deb0920da/1a144/IoT_MQTT_Panel_01.jpg",
              "alt": "IoT MQTT Panel",
              "title": "IoT MQTT Panel",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`We need to add the MQTT Server connection to get started. Type in the IP address of your MQTT Server - this either the local IP of your `}<a parentName="p" {...{
        "href": "/en/Advanced_User/OpenHAB_Home_Automation/#openhab2-configuration"
      }}>{`Windows machine`}</a>{` or your `}<a parentName="p" {...{
        "href": "/en/Advanced_User/openHABian_on_Raspberry_Pi/Mosquitto_Setup/"
      }}>{`Raspberry Pi`}</a>{` (depending on where you installed it). There we also added a MQTT login that we need to use here to connect to the Server and save your settings.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "728px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/60e86cf8d5ad3a303bce0c956cd4002c/7f450/IoT_MQTT_Panel_02.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "57.391304347826086%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAALABQDASIAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAAAAMCBf/EABYBAQEBAAAAAAAAAAAAAAAAAAECA//aAAwDAQACEAMQAAAB6+qkiinT/8QAGhAAAgIDAAAAAAAAAAAAAAAAAAIBEwMQEv/aAAgBAQABBQKcSlSFSb6k/8QAFxEBAAMAAAAAAAAAAAAAAAAAAAERE//aAAgBAwEBPwGoYv/EABcRAAMBAAAAAAAAAAAAAAAAAAABERP/2gAIAQIBAT8BrNGf/8QAGBAAAwEBAAAAAAAAAAAAAAAAAAExMhD/2gAIAQEABj8ChlGV2n//xAAaEAACAgMAAAAAAAAAAAAAAAABEBFhADHx/9oACAEBAAE/ISyIARW03GdrP//aAAwDAQACAAMAAAAQMw//xAAZEQACAwEAAAAAAAAAAAAAAAAAARFRYZH/2gAIAQMBAT8QdS4Qw//EABYRAAMAAAAAAAAAAAAAAAAAAAABYf/aAAgBAgEBPxCzKM//xAAaEAACAgMAAAAAAAAAAAAAAAAAARHxICGh/9oACAEBAAE/EGqjbai+ihwFdn//2Q==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/60e86cf8d5ad3a303bce0c956cd4002c/e4706/IoT_MQTT_Panel_02.avif 230w", "/en/static/60e86cf8d5ad3a303bce0c956cd4002c/d1af7/IoT_MQTT_Panel_02.avif 460w", "/en/static/60e86cf8d5ad3a303bce0c956cd4002c/b8765/IoT_MQTT_Panel_02.avif 728w"],
              "sizes": "(max-width: 728px) 100vw, 728px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/60e86cf8d5ad3a303bce0c956cd4002c/a0b58/IoT_MQTT_Panel_02.webp 230w", "/en/static/60e86cf8d5ad3a303bce0c956cd4002c/bc10c/IoT_MQTT_Panel_02.webp 460w", "/en/static/60e86cf8d5ad3a303bce0c956cd4002c/8cb3e/IoT_MQTT_Panel_02.webp 728w"],
              "sizes": "(max-width: 728px) 100vw, 728px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/60e86cf8d5ad3a303bce0c956cd4002c/e83b4/IoT_MQTT_Panel_02.jpg 230w", "/en/static/60e86cf8d5ad3a303bce0c956cd4002c/e41a8/IoT_MQTT_Panel_02.jpg 460w", "/en/static/60e86cf8d5ad3a303bce0c956cd4002c/7f450/IoT_MQTT_Panel_02.jpg 728w"],
              "sizes": "(max-width: 728px) 100vw, 728px",
              "type": "image/jpeg"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/60e86cf8d5ad3a303bce0c956cd4002c/7f450/IoT_MQTT_Panel_02.jpg",
              "alt": "IoT MQTT Panel",
              "title": "IoT MQTT Panel",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Now click on the Mosquitto Connection you just added and click the orange Plus Icon to add a new panel. Choose a Button to get started:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "730px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/c95949baa48e20ed9dc2ec24e1f48bb7/1a144/IoT_MQTT_Panel_03.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "81.73913043478261%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAQABQDASIAAhEBAxEB/8QAGAAAAgMAAAAAAAAAAAAAAAAAAAMBAgX/xAAUAQEAAAAAAAAAAAAAAAAAAAAB/9oADAMBAAIQAxAAAAHVu6EUME//xAAbEAACAgMBAAAAAAAAAAAAAAAAAQITAxASIf/aAAgBAQABBQJY4lUCqGn4dI//xAAXEQADAQAAAAAAAAAAAAAAAAAAARFR/9oACAEDAQE/AYsIj//EABcRAAMBAAAAAAAAAAAAAAAAAAABERL/2gAIAQIBAT8B0ys//8QAGRAAAwADAAAAAAAAAAAAAAAAAAEyEBEx/9oACAEBAAY/ApJRKxtnT//EABkQAQACAwAAAAAAAAAAAAAAAAEAESAxQf/aAAgBAQABPyHuODMgKUEE1H//2gAMAwEAAgADAAAAEEMP/8QAFhEBAQEAAAAAAAAAAAAAAAAAAQBh/9oACAEDAQE/EFmZl//EABYRAQEBAAAAAAAAAAAAAAAAAABhAf/aAAgBAgEBPxCq+v/EABoQAQEAAwEBAAAAAAAAAAAAAAEAETHxQVH/2gAIAQEAAT8Qag8+pq5lzIMW1glfIvIJq//Z')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/c95949baa48e20ed9dc2ec24e1f48bb7/e4706/IoT_MQTT_Panel_03.avif 230w", "/en/static/c95949baa48e20ed9dc2ec24e1f48bb7/d1af7/IoT_MQTT_Panel_03.avif 460w", "/en/static/c95949baa48e20ed9dc2ec24e1f48bb7/b6582/IoT_MQTT_Panel_03.avif 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/c95949baa48e20ed9dc2ec24e1f48bb7/a0b58/IoT_MQTT_Panel_03.webp 230w", "/en/static/c95949baa48e20ed9dc2ec24e1f48bb7/bc10c/IoT_MQTT_Panel_03.webp 460w", "/en/static/c95949baa48e20ed9dc2ec24e1f48bb7/87ca7/IoT_MQTT_Panel_03.webp 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/c95949baa48e20ed9dc2ec24e1f48bb7/e83b4/IoT_MQTT_Panel_03.jpg 230w", "/en/static/c95949baa48e20ed9dc2ec24e1f48bb7/e41a8/IoT_MQTT_Panel_03.jpg 460w", "/en/static/c95949baa48e20ed9dc2ec24e1f48bb7/1a144/IoT_MQTT_Panel_03.jpg 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/jpeg"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/c95949baa48e20ed9dc2ec24e1f48bb7/1a144/IoT_MQTT_Panel_03.jpg",
              "alt": "IoT MQTT Panel",
              "title": "IoT MQTT Panel",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Give your button a name, choose an MQTT Entry Point that you want to target - in the case above, we choose the `}<a parentName="p" {...{
        "href": "/en/Advanced_User/openHABian_on_Raspberry_Pi/Projects/Alarm_Trigger/"
      }}>{`Trigger Alarm`}</a>{` Node that can be targeted with the topic `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`ipcam/alarm/pushalarm`}</code>{`:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "730px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/09899228c7bc8452907d7bb2f324c118/e9beb/IoT_MQTT_Panel_04.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "66.95652173913044%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAANCAIAAAAmMtkJAAAACXBIWXMAAA7EAAAOxAGVKw4bAAACBElEQVQoz4WQW4/SUBSF+9P9BZoYH0ajiSbDDMhQGOi99PR6Wu4oAuHFoAFKZqAXOkMLpz2tQsiMxhi+rOysl5W1sgnThBKQgAwAABBaBmxAaH75OpjNZvP53LYXnudt/sDf+O5qPbc6028jAoiAqTB8jTd0XeZyMvNerVODNnQcxz6yXq99zzvI933P2wTBarFsvXg5pEWCpTjyiqRLtEALda4m0BWe5ToNIwg2S9teLGz/cRshFO522214UhglWbZyHEICgKVYkRMFVoCGpWuWphqT0Wi73e4RwnFyP/159316/2MWRfs4SVGMHx7DDGPPdQkTQkmsS5KkG7rRsMxWSzOt0WTiuW6EEFqtlyKwoXWnGmgTxDiNUYxQnGWZ53mEwIg3lyW6TCmKWua5QrWSK5Nmvxcem1OM490O7xGKoixNsyPp0biuS/C8WMqTFElxFG9IrEblpUpuPOgHwUMURRjjteOE4cEk+ESSJKdmaJnKEU3XOt1up9dtttqTyeSpBGOc/g3G+NTM8PWr4u31zW2erBXKVIGkaJbv93tP8/7lebaiaYIoAVnVdKjr8HAhHI9H2f95DlvNJpAVoKh1RYHNRrvdaUBrODwfdhyHAGS18vYjc5mvsuxnUaR5ofApb8jG2dmHsH7xjnl9UX/1plksmVBTqWu2+KFvyWfDv7/9C1aCqBP8+Yk8AAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/09899228c7bc8452907d7bb2f324c118/e4706/IoT_MQTT_Panel_04.avif 230w", "/en/static/09899228c7bc8452907d7bb2f324c118/d1af7/IoT_MQTT_Panel_04.avif 460w", "/en/static/09899228c7bc8452907d7bb2f324c118/b6582/IoT_MQTT_Panel_04.avif 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/09899228c7bc8452907d7bb2f324c118/a0b58/IoT_MQTT_Panel_04.webp 230w", "/en/static/09899228c7bc8452907d7bb2f324c118/bc10c/IoT_MQTT_Panel_04.webp 460w", "/en/static/09899228c7bc8452907d7bb2f324c118/87ca7/IoT_MQTT_Panel_04.webp 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/09899228c7bc8452907d7bb2f324c118/81c8e/IoT_MQTT_Panel_04.png 230w", "/en/static/09899228c7bc8452907d7bb2f324c118/08a84/IoT_MQTT_Panel_04.png 460w", "/en/static/09899228c7bc8452907d7bb2f324c118/e9beb/IoT_MQTT_Panel_04.png 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/09899228c7bc8452907d7bb2f324c118/e9beb/IoT_MQTT_Panel_04.png",
              "alt": "IoT MQTT Panel",
              "title": "IoT MQTT Panel",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`The necessary payload is injected by the dashboard button - `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`{"pushalarm" : "triggered"}`}</code>{`:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "730px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/f16f0f7d8e260bdf53f518bd60b4e2b1/e9beb/IoT_MQTT_Panel_05.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "67.82608695652173%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAOCAIAAACgpqunAAAACXBIWXMAAA7EAAAOxAGVKw4bAAACiUlEQVQoz02S63KaUBSFfdG+Q9+nl4xpbZIGvKGiXARB0USNk/ZP4tSJGkwUkTuHAwfOaYlpm2/23zVr7bV3QZK6kizJstxT1J6q9QdDdTC4u7ubz+f39/P1eu15nuM47hsc296vHg+GUeBbXLvBirzYV3i5c9aXmIHEPS4XjptrbNt23mLbXhjsV4/dd+8XolqoXFRq36tck+vyTa5+KrB0p8ksF/PtbrdY/Hp4WHqeFwSBHwS+73sv+J7nHizbsgo1qsbW2Fa1JXKSJI94ftDt9jZ6nhahJIzivWmbB2d/sAMAkxRHSRpGMMsyhFBBUVVZliVZ0jRtNh3PppPJ+Hq33YVhiDGOIPT9AMYJjJMEpWmGUYoRSgnBSZIUGIqpXtaUXk9QFIoXyrxAcfxS1yMQEULiOIYQEkLwkSzDWQ7GL2KqRFXOKo1ys8Nx7fo5QxUb1XN9swl8H2MMADBNE0KI0hSh9AhC6DU2z/FiR5BF6WqozUb9idYb9WXDMCII89gRdGwnhjB9A0Lo1ZnlhFabY9g2J0iCrHYVTe0Pnp+fAQBZlkVRFIbh0ecfSZK8Op/R9Qu6TtdZqs6el5kyw3I8r+u6Y9uu6wIAXN/DhBwnw8eVM0JI7jwaadpQG2nazejq583N7Y/b6XSyN/a+51uW5ThubNmxYSR7M94ZWQQxIWmaZlmWi1t1lqFr4qfPjZMTuVTqVRoNprPRn/IjAwAiuLseH67GzuzW1IZou8WExBDGcRxFUYEu0fVvlW7xK0dfiqcf2OJH+kvxab1CaZokCQChaRgoCFAYpgAcG/9fGFNlWrWWJKvjyURTBIWvSc3LJ30F/NC18se2Hcdy3byAl+ORv/wR/waJrNwJloQZ4gAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/f16f0f7d8e260bdf53f518bd60b4e2b1/e4706/IoT_MQTT_Panel_05.avif 230w", "/en/static/f16f0f7d8e260bdf53f518bd60b4e2b1/d1af7/IoT_MQTT_Panel_05.avif 460w", "/en/static/f16f0f7d8e260bdf53f518bd60b4e2b1/b6582/IoT_MQTT_Panel_05.avif 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/f16f0f7d8e260bdf53f518bd60b4e2b1/a0b58/IoT_MQTT_Panel_05.webp 230w", "/en/static/f16f0f7d8e260bdf53f518bd60b4e2b1/bc10c/IoT_MQTT_Panel_05.webp 460w", "/en/static/f16f0f7d8e260bdf53f518bd60b4e2b1/87ca7/IoT_MQTT_Panel_05.webp 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/f16f0f7d8e260bdf53f518bd60b4e2b1/81c8e/IoT_MQTT_Panel_05.png 230w", "/en/static/f16f0f7d8e260bdf53f518bd60b4e2b1/08a84/IoT_MQTT_Panel_05.png 460w", "/en/static/f16f0f7d8e260bdf53f518bd60b4e2b1/e9beb/IoT_MQTT_Panel_05.png 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/f16f0f7d8e260bdf53f518bd60b4e2b1/e9beb/IoT_MQTT_Panel_05.png",
              "alt": "IoT MQTT Panel",
              "title": "IoT MQTT Panel",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Save your settings and try out your brand new button:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "730px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/fce1f38ea06f9238960c892c1f501d50/1a144/IoT_MQTT_Panel_06.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "30.434782608695656%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAGABQDASIAAhEBAxEB/8QAFgABAQEAAAAAAAAAAAAAAAAAAAIF/8QAFAEBAAAAAAAAAAAAAAAAAAAAAf/aAAwDAQACEAMQAAAB0Qlgf//EABkQAAIDAQAAAAAAAAAAAAAAAAABAgMUBP/aAAgBAQABBQLNAzQFz1o//8QAFREBAQAAAAAAAAAAAAAAAAAAECH/2gAIAQMBAT8Bh//EABYRAAMAAAAAAAAAAAAAAAAAAAEQEf/aAAgBAgEBPwGlf//EABYQAAMAAAAAAAAAAAAAAAAAAAAQMf/aAAgBAQAGPwJQ/8QAGBAAAwEBAAAAAAAAAAAAAAAAAAER8CH/2gAIAQEAAT8h3EbiEHbP/9oADAMBAAIAAwAAABCED//EABgRAAIDAAAAAAAAAAAAAAAAAAEQEVGR/9oACAEDAQE/EIoMC//EABgRAAIDAAAAAAAAAAAAAAAAAAABEWGR/9oACAECAQE/ELHrJP/EAB0QAAEDBQEAAAAAAAAAAAAAAAEAYfARITFRcbH/2gAIAQEAAT8QJtyZNy4qYBfkgDxf/9k=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/fce1f38ea06f9238960c892c1f501d50/e4706/IoT_MQTT_Panel_06.avif 230w", "/en/static/fce1f38ea06f9238960c892c1f501d50/d1af7/IoT_MQTT_Panel_06.avif 460w", "/en/static/fce1f38ea06f9238960c892c1f501d50/b6582/IoT_MQTT_Panel_06.avif 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/fce1f38ea06f9238960c892c1f501d50/a0b58/IoT_MQTT_Panel_06.webp 230w", "/en/static/fce1f38ea06f9238960c892c1f501d50/bc10c/IoT_MQTT_Panel_06.webp 460w", "/en/static/fce1f38ea06f9238960c892c1f501d50/87ca7/IoT_MQTT_Panel_06.webp 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/fce1f38ea06f9238960c892c1f501d50/e83b4/IoT_MQTT_Panel_06.jpg 230w", "/en/static/fce1f38ea06f9238960c892c1f501d50/e41a8/IoT_MQTT_Panel_06.jpg 460w", "/en/static/fce1f38ea06f9238960c892c1f501d50/1a144/IoT_MQTT_Panel_06.jpg 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/jpeg"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/fce1f38ea06f9238960c892c1f501d50/1a144/IoT_MQTT_Panel_06.jpg",
              "alt": "IoT MQTT Panel",
              "title": "IoT MQTT Panel",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <h2 {...{
      "id": "mqtt-dashboard",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#mqtt-dashboard",
        "aria-label": "mqtt dashboard permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`MQTT Dashboard`}</h2>
    <p>{`First, go to the Google Play Store on your Android phone to download the App `}<a href="https://play.google.com/store/apps/details?id=com.thn.iotmqttdashboard&hl=en" target="_blank" rel="noopener noreferrer">{`MQTT Dashboard`}</a>{`.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "730px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/150e5a5c5b5f60576cd9c02f10ada278/1a144/MQTT_Dashboard_01.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "133.47826086956522%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAbABQDASIAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAAAAQBBf/EABYBAQEBAAAAAAAAAAAAAAAAAAABAv/aAAwDAQACEAMQAAAB6eViVUACZNf/xAAYEAADAQEAAAAAAAAAAAAAAAAAAQMUMP/aAAgBAQABBQLPMzzM8+KbP//EABYRAAMAAAAAAAAAAAAAAAAAAAAQEv/aAAgBAwEBPwFyf//EABQRAQAAAAAAAAAAAAAAAAAAACD/2gAIAQIBAT8BH//EABcQAQEBAQAAAAAAAAAAAAAAADEAMKH/2gAIAQEABj8CIjuLf//EABkQAAMAAwAAAAAAAAAAAAAAAABhkREwMf/aAAgBAQABPyF+j9H9LE6p/9oADAMBAAIAAwAAABB46gP/xAAXEQADAQAAAAAAAAAAAAAAAAAAAREQ/9oACAEDAQE/ENcOH//EABcRAAMBAAAAAAAAAAAAAAAAAAABERD/2gAIAQIBAT8QrK8p/8QAHBAAAgICAwAAAAAAAAAAAAAAAAERMSHxMEGR/9oACAEBAAE/EG2/ab6bFwOiZlue2Z//2Q==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/150e5a5c5b5f60576cd9c02f10ada278/e4706/MQTT_Dashboard_01.avif 230w", "/en/static/150e5a5c5b5f60576cd9c02f10ada278/d1af7/MQTT_Dashboard_01.avif 460w", "/en/static/150e5a5c5b5f60576cd9c02f10ada278/b6582/MQTT_Dashboard_01.avif 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/150e5a5c5b5f60576cd9c02f10ada278/a0b58/MQTT_Dashboard_01.webp 230w", "/en/static/150e5a5c5b5f60576cd9c02f10ada278/bc10c/MQTT_Dashboard_01.webp 460w", "/en/static/150e5a5c5b5f60576cd9c02f10ada278/87ca7/MQTT_Dashboard_01.webp 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/150e5a5c5b5f60576cd9c02f10ada278/e83b4/MQTT_Dashboard_01.jpg 230w", "/en/static/150e5a5c5b5f60576cd9c02f10ada278/e41a8/MQTT_Dashboard_01.jpg 460w", "/en/static/150e5a5c5b5f60576cd9c02f10ada278/1a144/MQTT_Dashboard_01.jpg 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/jpeg"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/150e5a5c5b5f60576cd9c02f10ada278/1a144/MQTT_Dashboard_01.jpg",
              "alt": "MQTT Dashboard",
              "title": "MQTT Dashboard",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`We need to add the MQTT Server connection to get started. Click in the Plus Icon and type in the IP address of your MQTT Server - this either the local IP of your `}<a parentName="p" {...{
        "href": "/en/Advanced_User/OpenHAB_Home_Automation/#openhab2-configuration"
      }}>{`Windows machine`}</a>{` or your `}<a parentName="p" {...{
        "href": "/en/Advanced_User/openHABian_on_Raspberry_Pi/Mosquitto_Setup/"
      }}>{`Raspberry Pi`}</a>{` (depending on where you installed it). There we also added a MQTT login that we need to use here to connect to the Server and save your settings.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "730px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/fb3344531263ee0a339b3ee4ddcc8e17/1a144/MQTT_Dashboard_02.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "76.95652173913044%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAPABQDASIAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAAAAQBBf/EABQBAQAAAAAAAAAAAAAAAAAAAAD/2gAMAwEAAhADEAAAAekq0lUj/8QAFxAAAwEAAAAAAAAAAAAAAAAAAAMUIP/aAAgBAQABBQKdZOsnXj//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAEDAQE/AT//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAECAQE/AT//xAAWEAEBAQAAAAAAAAAAAAAAAAAxACD/2gAIAQEABj8CIjH/xAAaEAACAgMAAAAAAAAAAAAAAAAAAWGREBHh/9oACAEBAAE/IUzsn2S7EtZ//9oADAMBAAIAAwAAABBI7//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQMBAT8QP//EABYRAAMAAAAAAAAAAAAAAAAAAAABEf/aAAgBAgEBPxCsrP/EABwQAAEDBQAAAAAAAAAAAAAAAAABIfEQEVFhkf/aAAgBAQABPxBGdfZLSYlhij6P/9k=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/fb3344531263ee0a339b3ee4ddcc8e17/e4706/MQTT_Dashboard_02.avif 230w", "/en/static/fb3344531263ee0a339b3ee4ddcc8e17/d1af7/MQTT_Dashboard_02.avif 460w", "/en/static/fb3344531263ee0a339b3ee4ddcc8e17/b6582/MQTT_Dashboard_02.avif 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/fb3344531263ee0a339b3ee4ddcc8e17/a0b58/MQTT_Dashboard_02.webp 230w", "/en/static/fb3344531263ee0a339b3ee4ddcc8e17/bc10c/MQTT_Dashboard_02.webp 460w", "/en/static/fb3344531263ee0a339b3ee4ddcc8e17/87ca7/MQTT_Dashboard_02.webp 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/fb3344531263ee0a339b3ee4ddcc8e17/e83b4/MQTT_Dashboard_02.jpg 230w", "/en/static/fb3344531263ee0a339b3ee4ddcc8e17/e41a8/MQTT_Dashboard_02.jpg 460w", "/en/static/fb3344531263ee0a339b3ee4ddcc8e17/1a144/MQTT_Dashboard_02.jpg 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/jpeg"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/fb3344531263ee0a339b3ee4ddcc8e17/1a144/MQTT_Dashboard_02.jpg",
              "alt": "MQTT Dashboard",
              "title": "MQTT Dashboard",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "730px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/90e9c8751527476e8032941444c7c730/1a144/MQTT_Dashboard_03.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "33.91304347826087%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAHABQDASIAAhEBAxEB/8QAFgABAQEAAAAAAAAAAAAAAAAAAAEF/8QAFAEBAAAAAAAAAAAAAAAAAAAAAP/aAAwDAQACEAMQAAAB0qAH/8QAGBAAAwEBAAAAAAAAAAAAAAAAAAMUAhH/2gAIAQEAAQUCnx2dROs//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAwEBPwE//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAgEBPwE//8QAFhABAQEAAAAAAAAAAAAAAAAAADGh/9oACAEBAAY/Ak1Nf//EABcQAAMBAAAAAAAAAAAAAAAAAABRYSH/2gAIAQEAAT8hWxFovH//2gAMAwEAAgADAAAAEAwv/8QAFREBAQAAAAAAAAAAAAAAAAAAEBH/2gAIAQMBAT8Qp//EABYRAAMAAAAAAAAAAAAAAAAAAAABEf/aAAgBAgEBPxCsrP/EABoQAQACAwEAAAAAAAAAAAAAAAEAIRFB8VH/2gAIAQEAAT8QXCuuyX7mdpOsn//Z')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/90e9c8751527476e8032941444c7c730/e4706/MQTT_Dashboard_03.avif 230w", "/en/static/90e9c8751527476e8032941444c7c730/d1af7/MQTT_Dashboard_03.avif 460w", "/en/static/90e9c8751527476e8032941444c7c730/b6582/MQTT_Dashboard_03.avif 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/90e9c8751527476e8032941444c7c730/a0b58/MQTT_Dashboard_03.webp 230w", "/en/static/90e9c8751527476e8032941444c7c730/bc10c/MQTT_Dashboard_03.webp 460w", "/en/static/90e9c8751527476e8032941444c7c730/87ca7/MQTT_Dashboard_03.webp 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/90e9c8751527476e8032941444c7c730/e83b4/MQTT_Dashboard_03.jpg 230w", "/en/static/90e9c8751527476e8032941444c7c730/e41a8/MQTT_Dashboard_03.jpg 460w", "/en/static/90e9c8751527476e8032941444c7c730/1a144/MQTT_Dashboard_03.jpg 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/jpeg"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/90e9c8751527476e8032941444c7c730/1a144/MQTT_Dashboard_03.jpg",
              "alt": "MQTT Dashboard",
              "title": "MQTT Dashboard",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Now click on the Connection you just added, choose `}<strong parentName="p">{`Subscribe`}</strong>{` and click the Plus Icon, in the top right, to add a new MQTT Subscription:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "730px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/77b9b518117ff3568d50c09906c4c2cb/1a144/MQTT_Dashboard_04.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "23.478260869565215%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAFABQDASIAAhEBAxEB/8QAFgABAQEAAAAAAAAAAAAAAAAAAAEF/8QAFAEBAAAAAAAAAAAAAAAAAAAAAP/aAAwDAQACEAMQAAAB0YFB/8QAFhAAAwAAAAAAAAAAAAAAAAAAAAMU/9oACAEBAAEFAp1k6ydZ/8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAwEBPwE//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAgEBPwE//8QAFxAAAwEAAAAAAAAAAAAAAAAAAAExkf/aAAgBAQAGPwKEZHp//8QAGRAAAQUAAAAAAAAAAAAAAAAAAQAQQZHx/9oACAEBAAE/ISWVrcZH/9oADAMBAAIAAwAAABD8L//EABURAQEAAAAAAAAAAAAAAAAAABAR/9oACAEDAQE/EKf/xAAWEQADAAAAAAAAAAAAAAAAAAAAARH/2gAIAQIBAT8QrKz/xAAZEAACAwEAAAAAAAAAAAAAAAAAASFRgfH/2gAIAQEAAT8QsMpCl2zpD//Z')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/77b9b518117ff3568d50c09906c4c2cb/e4706/MQTT_Dashboard_04.avif 230w", "/en/static/77b9b518117ff3568d50c09906c4c2cb/d1af7/MQTT_Dashboard_04.avif 460w", "/en/static/77b9b518117ff3568d50c09906c4c2cb/b6582/MQTT_Dashboard_04.avif 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/77b9b518117ff3568d50c09906c4c2cb/a0b58/MQTT_Dashboard_04.webp 230w", "/en/static/77b9b518117ff3568d50c09906c4c2cb/bc10c/MQTT_Dashboard_04.webp 460w", "/en/static/77b9b518117ff3568d50c09906c4c2cb/87ca7/MQTT_Dashboard_04.webp 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/77b9b518117ff3568d50c09906c4c2cb/e83b4/MQTT_Dashboard_04.jpg 230w", "/en/static/77b9b518117ff3568d50c09906c4c2cb/e41a8/MQTT_Dashboard_04.jpg 460w", "/en/static/77b9b518117ff3568d50c09906c4c2cb/1a144/MQTT_Dashboard_04.jpg 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/jpeg"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/77b9b518117ff3568d50c09906c4c2cb/1a144/MQTT_Dashboard_04.jpg",
              "alt": "MQTT Dashboard",
              "title": "MQTT Dashboard",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`We want to subscribe to the `}<em parentName="p">{`Trigger Alarm`}</em>{` MQTT Node that we already used for the `}<a parentName="p" {...{
        "href": "#iot-mqtt-panel"
      }}>{`IoT MQTT Panel`}</a>{`:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "730px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/5f71eba3a8b4bc5aa33222b32a1d26f4/1a144/MQTT_Dashboard_05.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "55.65217391304348%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAALABQDASIAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAAAAQBBf/EABQBAQAAAAAAAAAAAAAAAAAAAAD/2gAMAwEAAhADEAAAAejtYkVj/8QAFxAAAwEAAAAAAAAAAAAAAAAAAAMUIP/aAAgBAQABBQKdZOsnXj//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAEDAQE/AT//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAECAQE/AT//xAAXEAADAQAAAAAAAAAAAAAAAAAAMaEg/9oACAEBAAY/AkKiuP/EABkQAAIDAQAAAAAAAAAAAAAAAABhASBRkf/aAAgBAQABPyHSfR8P0f/aAAwDAQACAAMAAAAQiy//xAAVEQEBAAAAAAAAAAAAAAAAAAAAEf/aAAgBAwEBPxCq/8QAFhEAAwAAAAAAAAAAAAAAAAAAAAER/9oACAECAQE/EKys/8QAGxAAAgEFAAAAAAAAAAAAAAAAAAHxEBEhMXH/2gAIAQEAAT8QTcdRnBIDVLH/2Q==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/5f71eba3a8b4bc5aa33222b32a1d26f4/e4706/MQTT_Dashboard_05.avif 230w", "/en/static/5f71eba3a8b4bc5aa33222b32a1d26f4/d1af7/MQTT_Dashboard_05.avif 460w", "/en/static/5f71eba3a8b4bc5aa33222b32a1d26f4/b6582/MQTT_Dashboard_05.avif 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/5f71eba3a8b4bc5aa33222b32a1d26f4/a0b58/MQTT_Dashboard_05.webp 230w", "/en/static/5f71eba3a8b4bc5aa33222b32a1d26f4/bc10c/MQTT_Dashboard_05.webp 460w", "/en/static/5f71eba3a8b4bc5aa33222b32a1d26f4/87ca7/MQTT_Dashboard_05.webp 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/5f71eba3a8b4bc5aa33222b32a1d26f4/e83b4/MQTT_Dashboard_05.jpg 230w", "/en/static/5f71eba3a8b4bc5aa33222b32a1d26f4/e41a8/MQTT_Dashboard_05.jpg 460w", "/en/static/5f71eba3a8b4bc5aa33222b32a1d26f4/1a144/MQTT_Dashboard_05.jpg 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/jpeg"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/5f71eba3a8b4bc5aa33222b32a1d26f4/1a144/MQTT_Dashboard_05.jpg",
              "alt": "MQTT Dashboard",
              "title": "MQTT Dashboard",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Save your settings and switch to the `}<strong parentName="p">{`Publish`}</strong>{` tab to add a button that can trigger the MQTT Entry Node from our `}<a parentName="p" {...{
        "href": "/en/Advanced_User/openHABian_on_Raspberry_Pi/Projects/Alarm_Trigger/"
      }}>{`Node-RED Flow`}</a>{`:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "730px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/99aa6182ba06e09b1d6d4a92f1ea0894/1a144/MQTT_Dashboard_06.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "31.73913043478261%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAGABQDASIAAhEBAxEB/8QAFQABAQAAAAAAAAAAAAAAAAAAAAX/xAAUAQEAAAAAAAAAAAAAAAAAAAAA/9oADAMBAAIQAxAAAAGmAD//xAAWEAADAAAAAAAAAAAAAAAAAAAAAxT/2gAIAQEAAQUCnWTKJ1n/xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAEDAQE/AT//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAECAQE/AT//xAAWEAEBAQAAAAAAAAAAAAAAAAAAMaH/2gAIAQEABj8CiJr/xAAYEAADAQEAAAAAAAAAAAAAAAAAYZERQf/aAAgBAQABPyHXujFGx//aAAwDAQACAAMAAAAQjC//xAAVEQEBAAAAAAAAAAAAAAAAAAAQEf/aAAgBAwEBPxCn/8QAFhEAAwAAAAAAAAAAAAAAAAAAAAER/9oACAECAQE/EKys/8QAGhABAAEFAAAAAAAAAAAAAAAAAQAhMYGR4f/aAAgBAQABPxBNXZgF3zj3k//Z')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/99aa6182ba06e09b1d6d4a92f1ea0894/e4706/MQTT_Dashboard_06.avif 230w", "/en/static/99aa6182ba06e09b1d6d4a92f1ea0894/d1af7/MQTT_Dashboard_06.avif 460w", "/en/static/99aa6182ba06e09b1d6d4a92f1ea0894/b6582/MQTT_Dashboard_06.avif 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/99aa6182ba06e09b1d6d4a92f1ea0894/a0b58/MQTT_Dashboard_06.webp 230w", "/en/static/99aa6182ba06e09b1d6d4a92f1ea0894/bc10c/MQTT_Dashboard_06.webp 460w", "/en/static/99aa6182ba06e09b1d6d4a92f1ea0894/87ca7/MQTT_Dashboard_06.webp 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/99aa6182ba06e09b1d6d4a92f1ea0894/e83b4/MQTT_Dashboard_06.jpg 230w", "/en/static/99aa6182ba06e09b1d6d4a92f1ea0894/e41a8/MQTT_Dashboard_06.jpg 460w", "/en/static/99aa6182ba06e09b1d6d4a92f1ea0894/1a144/MQTT_Dashboard_06.jpg 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/jpeg"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/99aa6182ba06e09b1d6d4a92f1ea0894/1a144/MQTT_Dashboard_06.jpg",
              "alt": "MQTT Dashboard",
              "title": "MQTT Dashboard",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Here we need to add both the MQTT Topic to target that Node and the MQTT Payload required to trigger its execution:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "730px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/ddae3552d6488640e3c26bd8477fa867/1a144/MQTT_Dashboard_07.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "55.65217391304348%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAALABQDASIAAhEBAxEB/8QAGAAAAgMAAAAAAAAAAAAAAAAAAAQBAgX/xAAUAQEAAAAAAAAAAAAAAAAAAAAA/9oADAMBAAIQAxAAAAHRliwqNh//xAAXEAADAQAAAAAAAAAAAAAAAAAAAxQg/9oACAEBAAEFAp1kyydeP//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQMBAT8BP//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQIBAT8BP//EABYQAQEBAAAAAAAAAAAAAAAAADEAIP/aAAgBAQAGPwIiMf/EABoQAAEFAQAAAAAAAAAAAAAAAAABEDFhkRH/2gAIAQEAAT8h6SvS3pf0SX//2gAMAwEAAgADAAAAELsv/8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAwEBPxA//8QAFhEAAwAAAAAAAAAAAAAAAAAAAAER/9oACAECAQE/EKys/8QAGxAAAgIDAQAAAAAAAAAAAAAAACEBERAx8UH/2gAIAQEAAT8QhJL251Z0YlI08Qf/2Q==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/ddae3552d6488640e3c26bd8477fa867/e4706/MQTT_Dashboard_07.avif 230w", "/en/static/ddae3552d6488640e3c26bd8477fa867/d1af7/MQTT_Dashboard_07.avif 460w", "/en/static/ddae3552d6488640e3c26bd8477fa867/b6582/MQTT_Dashboard_07.avif 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/ddae3552d6488640e3c26bd8477fa867/a0b58/MQTT_Dashboard_07.webp 230w", "/en/static/ddae3552d6488640e3c26bd8477fa867/bc10c/MQTT_Dashboard_07.webp 460w", "/en/static/ddae3552d6488640e3c26bd8477fa867/87ca7/MQTT_Dashboard_07.webp 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/ddae3552d6488640e3c26bd8477fa867/e83b4/MQTT_Dashboard_07.jpg 230w", "/en/static/ddae3552d6488640e3c26bd8477fa867/e41a8/MQTT_Dashboard_07.jpg 460w", "/en/static/ddae3552d6488640e3c26bd8477fa867/1a144/MQTT_Dashboard_07.jpg 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/jpeg"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/ddae3552d6488640e3c26bd8477fa867/1a144/MQTT_Dashboard_07.jpg",
              "alt": "MQTT Dashboard",
              "title": "MQTT Dashboard",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Now save your settings and try out your new button:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "730px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/eb54f62c06adfd367e4ec272d42fda92/1a144/MQTT_Dashboard_08.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "33.47826086956522%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAHABQDASIAAhEBAxEB/8QAFgABAQEAAAAAAAAAAAAAAAAAAAIE/8QAFQEBAQAAAAAAAAAAAAAAAAAAAAH/2gAMAwEAAhADEAAAAd9ign//xAAYEAADAQEAAAAAAAAAAAAAAAAAAxQRE//aAAgBAQABBQLgvZlE6z//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAEDAQE/AT//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAECAQE/AT//xAAYEAACAwAAAAAAAAAAAAAAAAAAQQEykf/aAAgBAQAGPwKsjHp//8QAGhAAAQUBAAAAAAAAAAAAAAAAAAERMUFhkf/aAAgBAQABPyFWCT28GXRmH//aAAwDAQACAAMAAAAQg+//xAAWEQEBAQAAAAAAAAAAAAAAAAABEDH/2gAIAQMBAT8QcJ//xAAWEQADAAAAAAAAAAAAAAAAAAAAARH/2gAIAQIBAT8QrKz/xAAcEAACAAcAAAAAAAAAAAAAAAAAAREhUWFxgfH/2gAIAQEAAT8QZftiwymKobnaH//Z')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/eb54f62c06adfd367e4ec272d42fda92/e4706/MQTT_Dashboard_08.avif 230w", "/en/static/eb54f62c06adfd367e4ec272d42fda92/d1af7/MQTT_Dashboard_08.avif 460w", "/en/static/eb54f62c06adfd367e4ec272d42fda92/b6582/MQTT_Dashboard_08.avif 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/eb54f62c06adfd367e4ec272d42fda92/a0b58/MQTT_Dashboard_08.webp 230w", "/en/static/eb54f62c06adfd367e4ec272d42fda92/bc10c/MQTT_Dashboard_08.webp 460w", "/en/static/eb54f62c06adfd367e4ec272d42fda92/87ca7/MQTT_Dashboard_08.webp 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/eb54f62c06adfd367e4ec272d42fda92/e83b4/MQTT_Dashboard_08.jpg 230w", "/en/static/eb54f62c06adfd367e4ec272d42fda92/e41a8/MQTT_Dashboard_08.jpg 460w", "/en/static/eb54f62c06adfd367e4ec272d42fda92/1a144/MQTT_Dashboard_08.jpg 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/jpeg"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/eb54f62c06adfd367e4ec272d42fda92/1a144/MQTT_Dashboard_08.jpg",
              "alt": "MQTT Dashboard",
              "title": "MQTT Dashboard",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Switch back the `}<strong parentName="p">{`Subscribe`}</strong>{` tab to check if your payload was published successfully:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "730px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/7a965487962b7b2d63b786cb42c2876d/1a144/MQTT_Dashboard_09.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "33.04347826086957%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAHABQDASIAAhEBAxEB/8QAFgABAQEAAAAAAAAAAAAAAAAAAAEF/8QAFAEBAAAAAAAAAAAAAAAAAAAAAP/aAAwDAQACEAMQAAAB0oAH/8QAFhAAAwAAAAAAAAAAAAAAAAAAAAMU/9oACAEBAAEFAp1kyydZ/8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAwEBPwE//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAgEBPwE//8QAGBABAAMBAAAAAAAAAAAAAAAAAAExQZH/2gAIAQEABj8Caqev/8QAGhABAAEFAAAAAAAAAAAAAAAAAQAQQWGBkf/aAAgBAQABPyFez2YVumP/2gAMAwEAAgADAAAAEP8A7//EABURAQEAAAAAAAAAAAAAAAAAABAR/9oACAEDAQE/EKf/xAAWEQADAAAAAAAAAAAAAAAAAAAAARH/2gAIAQIBAT8QrKz/xAAbEAEAAgIDAAAAAAAAAAAAAAABABEhUWFxkf/aAAgBAQABPxALJvClIUzuTZ7J/9k=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/7a965487962b7b2d63b786cb42c2876d/e4706/MQTT_Dashboard_09.avif 230w", "/en/static/7a965487962b7b2d63b786cb42c2876d/d1af7/MQTT_Dashboard_09.avif 460w", "/en/static/7a965487962b7b2d63b786cb42c2876d/b6582/MQTT_Dashboard_09.avif 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/7a965487962b7b2d63b786cb42c2876d/a0b58/MQTT_Dashboard_09.webp 230w", "/en/static/7a965487962b7b2d63b786cb42c2876d/bc10c/MQTT_Dashboard_09.webp 460w", "/en/static/7a965487962b7b2d63b786cb42c2876d/87ca7/MQTT_Dashboard_09.webp 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/7a965487962b7b2d63b786cb42c2876d/e83b4/MQTT_Dashboard_09.jpg 230w", "/en/static/7a965487962b7b2d63b786cb42c2876d/e41a8/MQTT_Dashboard_09.jpg 460w", "/en/static/7a965487962b7b2d63b786cb42c2876d/1a144/MQTT_Dashboard_09.jpg 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/jpeg"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/7a965487962b7b2d63b786cb42c2876d/1a144/MQTT_Dashboard_09.jpg",
              "alt": "MQTT Dashboard",
              "title": "MQTT Dashboard",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      